const Datas = {
    resources: [
      {
        id: "slot1",
        name: "Slot 1"
      },
      {
        id: "slot2",
        name: "Slot 2"
      },  
      {
        id: "slot3",
        name: "Slot 3"
      },
      {
        id: "slot4",
        name: "Slot 4"
      },
      {
        id: "slot5",
        name: "Slot 5"
      },
      {
        id: "slot6",
        name: "Slot 6"
      },  
      {
        id: "slot7",
        name: "Slot 7"
      },
      {
        id: "slot8",
        name: "Slot 8"
      },
      {
        id: "slot9",
        name: "Slot 9"
      },     
      {
        id: "slot10",
        name: "Slot 10"
      },
      {
        id: "slot11",
        name: "Slot 11"
      },
      {
        id: "slot12",
        name: "Slot 12"
      },  
      {
        id: "slot13",
        name: "Slot 13"
      },
      {
        id: "slot14",
        name: "Slot 14"
      },
      {
        id: "slot15",
        name: "Slot 15"
      },
      {
        id: "slot16",
        name: "Slot 16"
      },  
      {
        id: "slot17",
        name: "Slot 17"
      },
      {
        id: "slot18",
        name: "Slot 18"
      },
      {
        id: "slot19",
        name: "Slot 19"
      },  
      {
        id: "slot20",
        name: "Slot 20"
      },
      {
        id: "slot21",
        name: "Slot 21"
      },
      {
        id: "slot22",
        name: "Slot 22"
      },
      {
        id: "slot23",
        name: "Slot 23"
      },
      {
        id: "slot24",
        name: "Slot 24"
      },
      {
        id: "slot25",
        name: "Slot 25"
      },
    ],
    events: [
      {
        id: 1,
        start: "2021-06-21 10:00:00",
        end: "2021-06-21 12:00:00",
        resourceId: "slot1",
        title: "Alberto Rossi",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T100000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 2,
        start: "2021-06-21 16:00:00",
        end: "2021-06-21 18:00:00",
        resourceId: "slot2",
        title: "Marco De Luigi",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T100000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 3,
        start: "2021-06-21 12:00:00",
        end: "2021-06-21 14:00:00",
        resourceId: "slot1",
        title: "Raffaella Verdi",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T120000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 4,
        start: "2021-06-21 12:00:00",
        end: "2021-06-21 14:00:00",
        resourceId: "slot3",
        title: "Oscar Del Cinema",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T120000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 5,
        start: "2021-06-21 14:00:00",
        end: "2021-06-21 16:00:00",
        resourceId: "slot4",
        title: "Pamela Certa",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T140000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 6,
        start: "2021-06-21 14:00:00",
        end: "2021-06-21 16:00:00",
        resourceId: "slot5",
        title: "Maria Paura",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T140000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 7,
        start: "2021-06-21 16:00:00",
        end: "2021-06-21 18:00:00",
        resourceId: "slot6",
        title: "Ennio Tullio",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T160000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 8,
        start: "2021-06-21 16:00:00",
        end: "2021-06-21 18:30:00",
        resourceId: "slot3",
        title: "Paolo Giallo",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T160000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 9,
        start: "2021-06-21 17:00:00",
        end: "2021-06-21 20:00:00",
        resourceId: "slot7",
        title: "Matilda Irosa",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T180000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 10,
        start: "2021-06-21 15:00:00",
        end: "2021-06-21 20:00:00",
        resourceId: "slot8",
        title: "Tito Letto",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T180000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 11,
        start: "2021-06-21 10:00:00",
        end: "2021-06-21 14:00:00",
        resourceId: "slot9",
        title: "Ines Seta",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T200000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 12,
        start: "2021-06-21 9:30:00",
        end: "2021-06-21 13:00:00",
        resourceId: "slot10",
        title: "Renato Ferita",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T200000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 13,
        start: "2021-06-21 12:00:00",
        end: "2021-06-21 15:00:00",
        resourceId: "slot11",
        title: "Antonio Desueto",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T220000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "green"
      },
      {
        id: 14,
        start: "2021-06-21 14:00:00",
        end: "2021-06-21 18:00:00",
        resourceId: "slot12",
        title: "Nando Tonnato",
        rrule: "FREQ=WEEKLY;DTSTART=20210621T220000Z;BYDAY=MO,TU,WE,TH,FR,SA,SU",
        bgColor: "red"
      }
    ]
  };
  
  export default Datas;
  