import { Card, CardContent, CardMedia, Typography, CardHeader } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { properties } from "../utils/properties";
import Booking from "./Booking";
import logo from "../assets/logo_aquilone2.png";

function getName() {
  //let result = 'Benvenuto ';
  //result=result+localStorage.getItem("username")  
  let result = 'Benvenuto Admin';
  return result;
}


function getAdminRole(){
  let result=localStorage.getItem('admin');
  return result;
}

function getRole(){
  let result="UNDEFINED";
  console.log("r11",result,localStorage.getItem('ruolo'));
  if(localStorage.getItem('ruolo')!=undefined && localStorage.getItem('ruolo')!== null) {
    console.log("r22 into",localStorage.getItem('ruolo'));      
    result=localStorage.getItem('ruolo');
  }
  console.log("called 33 getrole", result);
  return result;
}

export const Dashboard = () => {
 

 
  const search = () => {
     
  }

  useEffect(() => {
    console.log("UE");    


  }, []);


  
  return (
    <Card>
    <CardHeader title={getName()} style={{textAlign:"left"}}/>
    <CardMedia
        component="img"
        style={{ width:"100%", maxHeight:"300"}}
        //classes={{root: classes.logo}}
        image={logo}
        alt="logo"
      />
    {getAdminRole() ==1 || getAdminRole() >3 || getRole()=='USER-ENTE' ? (
    <CardContent>       
     
      <Typography variant="body1" color="text.secondary">          
            ZeroSei Versione  {properties.version}
      </Typography>
    </CardContent>
    ):(
<CardContent>    
      {/*<Typography variant="body1" color="text.secondary">
          Dal menù a sinistra è possibile accedere alle diverse sezioni.
      </Typography>
      */}
      <Booking/>

      <Typography variant="body1" color="text.secondary">          
            ZeroSei Versione  {properties.version}
      </Typography>
    </CardContent>
    )}
  </Card>
      );  


};