import { Admin, fetchUtils,Resource, ListGuesser,EditGuesser, resolveBrowserLocale } from "react-admin";
//import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import italianCustom from '../Translations/italianCustom';
import { useStore } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

import merge from 'deepmerge';
import React, { useEffect, useState } from "react";
import { useNavigate, } from 'react-router-dom';
import { UserList,UserEdit, UserCreate } from "./users";
import { EnteList,EnteEdit, EnteCreate } from "./enti";
import { EnteAdminList,EnteAdminEdit, EnteAdminCreate } from "./entiAdmin";
import { EnteNoAdminList } from "./entiNoAdmin";
import { EnteCSPList } from "./entiCSP";
//import { CircuitoList,CircuitoEdit, CircuitoCreate } from "./circuiti";
import { GenitoreList,GenitoreEdit, GenitoreCreate } from "./genitori";
import { QuestionarioList, QuestionarioEdit, QuestionarioCreate } from "./questionari";
import { QuestionarioNoAdminList } from "./questionariNoAdmin";
import { BambinoList, BambinoEdit, BambinoCreate } from "./bambini";
import { BambinoAdminList, BambinoAdminEdit, BambinoAdminCreate } from "./bambiniAdmin";
import { IscrizioneList, IscrizioneEdit, IscrizioneCreate } from "./iscrizioni";
import { ImportList, ImportEdit } from "./imports";
import { PrenotazioniList } from "./prenotazioni";
import { ConsuntivazioneList, ConsuntivazioneEdit } from "./consuntivazioni";
import { ConsuntivazioneAdminList, ConsuntivazioneAdminEdit } from "./consuntivazioniAdmin";
import { ConsuntivazioneNoAdminList } from "./consuntivazioniNoAdmin";
import { ConsuntivazioneCSPList } from "./consuntivazioniCSP";
import { BambinoNoAdminList } from "./bambiniNoAdmin";
import { BambinoCSPList } from "./bambiniCSP";
import { Dashboard } from './dashboard';
import  {authProvider} from './authProvider';
import treeqlProvider from 'ra-data-treeql';
import LoginXKE from "./LoginXKE";
import {properties} from "../utils/properties";
import {setAdminStatusChangeCallback} from './authProvider';



export default function MyAdmin() {

    const [isAdmin, setIsAdmin] = useState(0);                
    const raLanguageItalian = require("../ra-italian/packages/ra-language-italian");
    const navigate = useNavigate();    
    
    const messages = {      
      it: merge(raLanguageItalian, italianCustom)
    };
    const i18nProvider = polyglotI18nProvider(
      (locale) => {
        //const localeMessages = messages[locale] ? messages[locale] : messages.it;
        const localeMessages = messages.it;
        console.log('i18nProvider: polyglotI18nProvider', {locale, localeMessages});
        return localeMessages;
      },
      resolveBrowserLocale()  
    );

    const handleAdminStatusChange = (status) => {
      console.log("HASC Called handle", status);

      setIsAdmin(status);
    };
    
    function getRole(){
      let result="UNDEFINED";
      console.log("r1",result,localStorage.getItem('ruolo'));
      if(localStorage.getItem('ruolo')!=undefined && localStorage.getItem('ruolo')!== null) {
        console.log("r2 into",localStorage.getItem('ruolo'));      
        result=localStorage.getItem('ruolo');
      }
      console.log("called 11 getrole", result);
      return result;
    }

 

    useEffect(() => {                
        console.log("A2B1",isAdmin);
        const storedAdminStatus = localStorage.getItem('admin');
        //const parsedAdminStatus = storedAdminStatus ? JSON.parse(storedAdminStatus) : false;
        const parsedAdminStatus = storedAdminStatus ? JSON.parse(storedAdminStatus) : 0;
        setIsAdmin(parsedAdminStatus);
        //console.log("A2B2",isAdmin);
        setAdminStatusChangeCallback(handleAdminStatusChange);        
        console.log("useEffect");        
        
        },[]);

    useEffect(() => {         
          console.log("A1B1");
          localStorage.setItem('admin', JSON.stringify(isAdmin));
          console.log("A1B2");
        }, [isAdmin]);


    return ( 
      <div>
   {/**
  <Admin loginPage={LoginXKE} disableTelemetry dataProvider={treeqlProvider(properties.productionUrl+'/php/core/api/api.php/')} dashboard={Dashboard} authProvider={authProvider} i18nProvider={i18nProvider}>      
<Admin loginPage={LoginXKE} disableTelemetry dataProvider={simpleRestProvider(properties.productionUrl+'/php/core/api/api.php/')} dashboard={Dashboard} authProvider={authProvider} i18nProvider={i18nProvider}>  
*/} 

<Admin loginPage={LoginXKE} disableTelemetry dataProvider={treeqlProvider(properties.productionUrl+'/php/core/api/api.php/')} dashboard={Dashboard} authProvider={authProvider} i18nProvider={i18nProvider}>      
      {isAdmin >0 &&isAdmin <3 && <Resource name="users" list={UserList}  edit={UserEdit} create={UserCreate}/>}
          
      {isAdmin ==0 && <Resource name="enti" list={EnteNoAdminList}  />}
      {isAdmin ==1 && <Resource name="enti" list={EnteList}  edit={EnteEdit} create={EnteCreate} />}
      {isAdmin ==2 && <Resource name="enti" list={EnteAdminList}  edit={EnteAdminEdit} create={EnteAdminCreate} /> }
      {isAdmin ==3 && <Resource name="enti" list={EnteCSPList} />}
      {isAdmin >3 && <Resource name="enti" list={EnteNoAdminList}  />}

      {isAdmin ==2 && <Resource name="genitori" list={GenitoreList}  edit={GenitoreEdit} create={GenitoreCreate} /> }

      {/*
      {isAdmin ==2 && <Resource name="questionari" list={QuestionarioList}  edit={QuestionarioEdit} create={QuestionarioCreate} />}
      */}

      {/**{isAdmin ==0 && getRole()=='ABBONAMENTI-MUSEI' && <Resource name="bambini" list={BambinoNoAdminList} />}*/}
      {isAdmin ==0 && <Resource name="bambini" list={BambinoNoAdminList} />}
      {isAdmin ==1 && <Resource name="bambini" list={BambinoList}  edit={BambinoEdit} create={BambinoCreate}/>} 
      {isAdmin ==2 && <Resource name="bambini" list={BambinoAdminList}  edit={BambinoAdminEdit} create={BambinoAdminCreate}/> }
      {isAdmin ==3 && <Resource name="bambini" list={BambinoCSPList} />}
      {isAdmin >3 && <Resource name="bambini" list={BambinoNoAdminList} />}
      
      {isAdmin ==2 && <Resource name="iscrizioni" list={IscrizioneList}  edit={IscrizioneEdit} create={IscrizioneCreate} /> }

      {/*{isAdmin ==0 && getRole()=='ABBONAMENTI-MUSEI' &&<Resource name="prenotazioni" list={PrenotazioniList}  />} */}
      {isAdmin ==0 && <Resource name="prenotazioni" list={PrenotazioniList}  />}
      {isAdmin ==1 && <Resource name="prenotazioni" list={PrenotazioniList}  />}
      {isAdmin ==2 && <Resource name="prenotazioni" list={PrenotazioniList}  />}

      {/*{isAdmin ==0 && getRole()=='ABBONAMENTI-MUSEI' && <Resource name="consuntivazioni" list={ConsuntivazioneNoAdminList} />} */}
      {isAdmin ==0 && <Resource name="consuntivazioni" list={ConsuntivazioneNoAdminList} />} 
      {isAdmin ==1 && <Resource name="consuntivazioni" list={ConsuntivazioneList}  edit={ConsuntivazioneEdit}/> }      
      {isAdmin ==2 && <Resource name="consuntivazioni" list={ConsuntivazioneAdminList}  edit={ConsuntivazioneAdminEdit}/> }      
      {isAdmin ==3 && <Resource name="consuntivazioni" list={ConsuntivazioneCSPList} />}
      {isAdmin >3  && <Resource name="consuntivazioni" list={ConsuntivazioneNoAdminList} />}
                      
      
      </Admin>
      </div>           
      

    );
    
  
  }  


  //id	username	nome	cognome	email	ruolo	password	creationtime	ente_id	telefono	

//ADMIN = 1
//CSP =3
//AM= 0