//import { List, Datagrid, TextField, EmailField } from "react-admin";
import { List, SimpleList, DateField, Datagrid, TextField, FunctionField, EmailField, UrlField, EditButton } from "react-admin";
import { Edit, Create, TopToolbar, SelectColumnsButton,CreateButton,ExportButton,DatagridConfigurable, SimpleForm, ReferenceInput, TextInput, ReferenceField, SelectInput} from "react-admin";
import { BulkDeleteButton, BulkExportButton } from 'react-admin';
import { useNotify, useRefresh, useRedirect, useDataProvider} from 'react-admin';
import { Fragment,useState } from 'react';
import { MyEmailField } from "./MyUrlField";
import jsSHA from 'jssha';
import { PasswordInput } from 'react-admin';
import Button from '@mui/material/Button';
import { useRecordContext } from 'react-admin';
import { required } from 'react-admin';
import { properties } from "../utils/properties";
import SendIcon from '@mui/icons-material/Send';
import {  FilterButton } from "ra-ui-materialui";
import { Box, Typography } from "@mui/material";
//import {CustomButton} from "./CustomButton";

const GenitoreBulkActionButtons = () => (
  <Fragment>
      <BulkExportButton />      
      <BulkDeleteButton mutationMode="pessimistic"/>
  </Fragment>
);



const GenitoreFilters = [
  <TextInput label="Cerca" source="q" alwaysOn />
];



const GenitoreActions = (props) => {
  const {
    className,
    basePath,    
    resource,
    currentSort,
    filterValues,
    exporter,
  } = props;
  return (
    <TopToolbar className={className}>
      <SelectColumnsButton />            
      <FilterButton/>
      <CreateButton basePath={basePath} /> 
      <ExportButton         
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />    
    </TopToolbar>
  );
};



export const GenitoreList = () => (
  <List  filters={GenitoreFilters} perPage={50} actions={<GenitoreActions />}>    
    <DatagridConfigurable bulkActionButtons={<GenitoreBulkActionButtons />}>       
      <TextField source="id" />      
      <TextField source="nome" />
      <TextField source="cognome" />
      <TextField source="relazione_con_bambino" label="Ruolo"/>
      <TextField source="codice_fiscale" label="Codice fiscale"/>
      <TextField source="data_nascita" label="Data di nascita"/>
      <TextField source="citta_nascita" label="Città di nascita"/>      
      <TextField source="stato_nascita" label="Stato di nascita"/>
      <TextField source="cittadinanza" />
      <TextField source="citta_residenza" label="Città di residenza"/>
      <TextField source="indirizzo_residenza" label="Indirizzo di residenza"/>
      <TextField source="citta_domicilio" label="Città di domicilio"/>
      <TextField source="indirizzo_domicilio" label="Indirizzo di domicilio"/>
      <MyEmailField source="email" />
      <TextField source="telefono" />
      
      <EditButton/>      
    </DatagridConfigurable>
  </List>
);



export const GenitoreEdit = () => {
  const notify = useNotify();  
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const transform = (data) => {

    const sanitizedData = {};
    for (const key in data) {
        sanitizedData[key] = data[key]; 
    }
    sanitizedData["creationtime"]=new Date().toISOString().slice(0, 19).replace('T', ' ');    
    return sanitizedData; 
};

  const handleEdit = async (values) => {
    try {
      var data2=transform(values);
      console.log("Editing",data2,values);
      const { data } = await dataProvider.update('genitori', { id:data2.id, data: data2 });          
      console.log('Resource updated successfully:', data, values);          
      notify("Dati aggiornati con successo!", { messageArgs: { smart_count: 1 } });
      redirect('/genitori');                  
    } catch (error) {
      // Handle error
    }
  };

  return(
  <Edit title={<username />} mutationMode="pessimistic" transform={transform}>     
      <SimpleForm onSubmit={handleEdit} sx={{ maxWidth: '100%' }}>  
      <Typography variant="h6" gutterBottom>
          Dati genitore
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="nome" fullWidth/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="cognome" fullWidth/>
        </Box>
    </Box> 

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="relazione_con_bambino" label="Ruolo" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth />
          {/*<TextInput source="codice_fiscale" label="Codice fiscale" fullWidth validate={required()}/>*/}
        </Box>
        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="data_nascita" label="Data di nascita" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="citta_nascita" label="Città di nascita" fullWidth/>              
        </Box>
        
    </Box>


    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="stato_nascita" label="Stato di nascita" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="cittadinanza"  fullWidth/>              
        </Box>
        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="citta_residenza" label="Città di residenza" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth/>              
        </Box>        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="citta_domicilio" label="Città di domicilio" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="indirizzo_domicilio" label="Indirizzo di domicilio" fullWidth/>              
        </Box>        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="email" fullWidth/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="telefono" fullWidth/>
        </Box>
    </Box>  
{/*//
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        </Box>
    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
    <ReferenceInput source="ruolo_id" reference="ruoli" label="Ruolo" perPage={1000}>  
        <SelectInput sx={{textAlign: "left"}} option="ruolo" optionText="ruolo" options={{fullWidth:true}} fullWidth/>
      </ReferenceInput> 
    </Box>
</Box>
*/}
<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>            
        <ReferenceInput source="id_altro_contatto" reference="genitori" label="Altro contatto" perPage={1000}>      
            <SelectInput option="nome" optionText="nome"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth />
        </ReferenceInput>     
    </Box>   
</Box>  
    </SimpleForm>
  </Edit> );
};

/*
function password(password) {
  const passwordBytes = new TextEncoder().encode(password);  
  const shaObj = new jsSHA('SHA-1', 'ARRAYBUFFER', {"encoding" : "UTF8", "numRounds" : 2});
  shaObj.update(passwordBytes.buffer);
  const hash = shaObj.getHash('HEX');
  return `*${hash.toUpperCase()}`;
}*/

/*function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}*/



const transform2 = (data) => {  
  const sanitizedData = {};
  for (const key in data) {    
      sanitizedData[key] = data[key]; 
  }
  sanitizedData["creationtime"]=new Date().toISOString().slice(0, 19).replace('T', ' ');  
  return sanitizedData; 
};


function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}


/*
const doResetMail = function(email,username,password) {    
  var xhr = new XMLHttpRequest();        
  xhr.open('POST', properties.productionUrl+'/rest/doSend',true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr.onload = function (e) {      
    if (xhr.readyState === 4) {
      if (xhr.status != 200) {                     
        console.error("Error 16", xhr.statusText);          
        alert("Reset Password fallito");  
      }
    }
  };  
  xhr.onerror = function (e) {
    console.error("Error 26", xhr.statusText);
  };      
  let urlEncodedData = "mail=" + email +"&user="+ username+"&password="+ b64EncodeUnicode(password)+"&action=reset"; 
  xhr.send(urlEncodedData);
}
*/

/*
const doRegisterMail = function(email,password,username) {    
  var xhr = new XMLHttpRequest();          
  xhr.open('POST', properties.productionUrl+'/rest/doSend',true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");  
  xhr.onload = function (e) {      
    if (xhr.readyState === 4) {
      if (xhr.status != 200) {                     
        console.error("Error 16", xhr.statusText);          
        alert("Reset Password fallito");  
      }
    }
  };
  xhr.onerror = function (e) {
    console.error("Error 26", xhr.statusText);
  };    
  let urlEncodedData = "mail=" + email +"&password="+ b64EncodeUnicode(password)+"&action=register"+"&user="+ username; 
  xhr.send(urlEncodedData);
}
*/

  export const GenitoreCreate = ({ onSuccess, ...props }) => {
    const notify = useNotify();    
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    
    const handleCreate = async (values) => {
      try {
        var data2=transform2(values);
        const { data } = await dataProvider.create('genitori', { data: data2 });
        
        // Custom logic after successful creation
        console.log('Resource created successfully:', data, values);
        //doRegisterMail(values.email, values.password, values.username);        
        notify("Utente creato correttamente! Una mail è stata inviata all'indirizzo inserito con la password generata", { messageArgs: { smart_count: 1 } });
        redirect('/genitori');        
      } catch (error) {
        notify("Qualcosa è andato storto nella creazione dell'utente. Riprovare più tardi");
      }
    };

  return(
  <Create transform={transform2}  {...props} >   
    <SimpleForm onSubmit={handleCreate} sx={{ maxWidth: '100%' }}>  
    <Typography variant="h6" gutterBottom>
          Dati genitore
    </Typography>       
    
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="nome" fullWidth/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="cognome" fullWidth/>
        </Box>
    </Box> 

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="relazione_con_bambino" label="Ruolo" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="codice_fiscale" label="Codice fiscale" fullWidth />
          {/*<TextInput source="codice_fiscale" label="Codice fiscale" fullWidth validate={required()}/>*/}
        </Box>
        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="data_nascita" label="Data di nascita" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="citta_nascita" label="Città di nascita" fullWidth/>              
        </Box>
        
    </Box>


    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="stato_nascita" label="Stato di nascita" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
        <TextInput source="cittadinanza"  fullWidth/>              
        </Box>
        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="citta_residenza" label="Città di residenza" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="indirizzo_residenza" label="Indirizzo di residenza" fullWidth/>              
        </Box>        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="citta_domicilio" label="Città di domicilio" fullWidth/>    
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="indirizzo_domicilio" label="Indirizzo di domicilio" fullWidth/>              
        </Box>        
    </Box>

    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="email" fullWidth/>
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="telefono" fullWidth/>
        </Box>
    </Box>  
{/*//
    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
        </Box>
    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
    <ReferenceInput source="ruolo_id" reference="ruoli" label="Ruolo" perPage={1000}>  
        <SelectInput sx={{textAlign: "left"}} option="ruolo" optionText="ruolo" options={{fullWidth:true}} fullWidth/>
      </ReferenceInput> 
    </Box>
</Box>
*/}
<Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>            
        <ReferenceInput source="id_altro_contatto" reference="genitori" label="Altro contatto" perPage={1000}>      
            <SelectInput option="nome" optionText="nome"  options={{fullWidth:true}} sx={{textAlign: "left"}} fullWidth />
        </ReferenceInput>     
    </Box>   
</Box>  
</SimpleForm>
  </Create> );




};

